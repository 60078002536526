import { Chart as ChartJS, ChartType } from "chart.js"

declare module "chart.js" {
	interface PluginOptionsByType<TType extends ChartType> {
		heatmapBackground?: {
			heatmapData?: HeatMapDataType
		}
	}
}

interface HeatmapCell {
	count: number
	normalized: number // Value between 0 and 1
}

type ChartState = {
	memory: {
		background: OffscreenCanvas
		lastDataSnapshot: string
	} | null
}

export type HeatMapDataType = {
	grid: HeatmapCell[][]
	maxCount: number
	maxX: number
	maxY: number
	xSegments: number
	ySegments: number
}

export class HeatmapClass {
	xSegments: number
	ySegments: number
	maxX: number
	minX: number
	maxY: number
	minY: number
	grid: HeatmapCell[][]
	maxCount: number = 0

	constructor(
		xSegments: number,
		ySegments: number,
		minX: number,
		maxX: number,
		minY: number,
		maxY: number
	) {
		this.xSegments = xSegments
		this.ySegments = ySegments
		this.minX = minX
		this.maxX = maxX
		this.maxY = maxY
		this.minY = minY
		this.grid = Array.from({ length: ySegments }, () =>
			Array.from({ length: xSegments }, () => ({ count: 0, normalized: 0 }))
		)
	}

	addDataPoint(x: number, y: number) {
		const xIndex = Math.min(
			Math.floor((x - this.minX) / ((this.maxX - this.minX) / this.xSegments)),
			this.xSegments - 1
		)
		const yIndex = Math.min(
			Math.floor((y - this.minY) / ((this.maxY - this.minY) / this.ySegments)),
			this.ySegments - 1
		)

		const cell = this.grid[yIndex]?.[xIndex]
		if (cell != undefined) {
			cell.count += 1
			this.maxCount = Math.max(this.maxCount, cell.count)
		}
	}

	normalizeData() {
		for (const row of this.grid) {
			for (const cell of row) {
				cell.normalized = cell.count / this.maxCount
			}
		}
	}
}

// export const downsampleGrid = (grid: number[][], targetSize: number): number[][] => {
// 	const originalSize = grid.length
// 	const ratio = originalSize / targetSize

// 	const downsampledGrid2: number[][] = []

// 	for (let y = 0; y < targetSize; y++) {
// 		const sums: number[] = []
// 		for (let x = 0; x < targetSize; x++) {
// 			let sum = 0
// 			let count = 0
// 			for (let yy = 0; yy < ratio; yy++) {
// 				for (let xx = 0; xx < ratio; xx++) {
// 					const originalY = Math.floor(y * ratio + yy)
// 					const originalX = Math.floor(x * ratio + xx)
// 					sum += grid[originalY]?.[originalX] ?? 0
// 					count++
// 				}
// 			}
// 			sums.push(sum / count)
// 		}
// 		downsampledGrid2.push(sums)
// 	}

// 	return downsampledGrid2
// }
// export const downsampleGridXY = (
// 	grid: number[][],
// 	targetSizeX: number,
// 	targetSizeY: number
// ): number[][] | undefined => {
// 	const originalSizeX = grid.length
// 	const originalSizeY = grid[0]?.length
// 	if (originalSizeY !== undefined) {
// 		const ratioX = originalSizeX / targetSizeX
// 		const ratioY = originalSizeY / targetSizeY
// 		const downsampledGrid2: number[][] = []

// 		for (let y = 0; y < targetSizeY; y++) {
// 			const sums: number[] = []
// 			for (let x = 0; x < targetSizeX; x++) {
// 				let sum = 0
// 				let count = 0
// 				for (let yy = 0; yy < ratioY; yy++) {
// 					for (let xx = 0; xx < ratioX; xx++) {
// 						const originalY = Math.floor(y * ratioY + yy)
// 						const originalX = Math.floor(x * ratioX + xx)
// 						sum += grid[originalY]?.[originalX] ?? 0
// 						count++
// 					}
// 				}
// 				sums.push(sum / count)
// 			}
// 			downsampledGrid2.push(sums)
// 		}

// 		return downsampledGrid2
// 	}
// }

// export const upsampleGridXY = (
// 	grid: number[][],
// 	targetSizeX: number,
// 	targetSizeY: number
// ): number[][] | undefined => {
// 	const originalSizeX = grid.length
// 	const ratioX = targetSizeX / originalSizeX
// 	const originalSizeY = grid[0]?.length
// 	if (originalSizeY !== undefined) {
// 		const ratioY = targetSizeY / originalSizeY

// 		const upsampledGrid: number[][] = []

// 		for (let y = 0; y < targetSizeY; y++) {
// 			const row: number[] = []
// 			for (let x = 0; x < targetSizeX; x++) {
// 				const originalY = Math.floor(y / ratioY)
// 				const originalX = Math.floor(x / ratioX)

// 				// Linear interpolation
// 				const yRatio = y / ratioY - originalY
// 				const xRatio = x / ratioX - originalX

// 				const topLeft = grid[originalY]?.[originalX] ?? 0
// 				const topRight = grid[originalY]?.[originalX + 1] ?? topLeft
// 				const bottomLeft = grid[originalY + 1]?.[originalX] ?? topLeft
// 				const bottomRight = grid[originalY + 1]?.[originalX + 1] ?? topLeft

// 				const top = topLeft * (1 - xRatio) + topRight * xRatio
// 				const bottom = bottomLeft * (1 - xRatio) + bottomRight * xRatio

// 				const value = top * (1 - yRatio) + bottom * yRatio
// 				row.push(value)
// 			}
// 			upsampledGrid.push(row)
// 		}

// 		return upsampledGrid
// 	}
// }

// export const upsampleGrid = (grid: number[][], targetSize: number): number[][] => {
// 	const originalSize = grid.length
// 	const ratio = targetSize / originalSize

// 	const upsampledGrid: number[][] = []

// 	for (let y = 0; y < targetSize; y++) {
// 		const row: number[] = []
// 		for (let x = 0; x < targetSize; x++) {
// 			const originalY = Math.floor(y / ratio)
// 			const originalX = Math.floor(x / ratio)

// 			// Linear interpolation
// 			const yRatio = y / ratio - originalY
// 			const xRatio = x / ratio - originalX

// 			const topLeft = grid[originalY]?.[originalX] ?? 0
// 			const topRight = grid[originalY]?.[originalX + 1] ?? topLeft
// 			const bottomLeft = grid[originalY + 1]?.[originalX] ?? topLeft
// 			const bottomRight = grid[originalY + 1]?.[originalX + 1] ?? topLeft

// 			const top = topLeft * (1 - xRatio) + topRight * xRatio
// 			const bottom = bottomLeft * (1 - xRatio) + bottomRight * xRatio

// 			const value = top * (1 - yRatio) + bottom * yRatio
// 			row.push(value)
// 		}
// 		upsampledGrid.push(row)
// 	}

// 	return upsampledGrid
// }

// export const resizeGrid = (
// 	grid: number[][],
// 	targetWidth: number,
// 	targetHeight: number
// ): number[][] => {
// 	const originalHeight = grid.length
// 	const originalWidth = grid[0]?.length ?? 0

// 	const resizedGrid: number[][] = []

// 	const yRatio = originalHeight / targetHeight
// 	const xRatio = originalWidth / targetWidth

// 	for (let y = 0; y < targetHeight; y++) {
// 		const row: number[] = []
// 		for (let x = 0; x < targetWidth; x++) {
// 			const originalY = Math.floor(y * yRatio)
// 			const originalX = Math.floor(x * xRatio)

// 			// Linear interpolation
// 			const yFactor = y * yRatio - originalY
// 			const xFactor = x * xRatio - originalX

// 			const topLeft = grid[originalY]?.[originalX] ?? 0
// 			const topRight = grid[originalY]?.[originalX + 1] ?? topLeft
// 			const bottomLeft = grid[originalY + 1]?.[originalX] ?? topLeft
// 			const bottomRight = grid[originalY + 1]?.[originalX + 1] ?? topLeft

// 			const top = topLeft * (1 - xFactor) + topRight * xFactor
// 			const bottom = bottomLeft * (1 - xFactor) + bottomRight * xFactor

// 			const value = top * (1 - yFactor) + bottom * yFactor
// 			row.push(value)
// 		}
// 		resizedGrid.push(row)
// 	}

// 	return resizedGrid
// }

export const fitGridGPT = (
	targetSize: { x: number; y: number },
	targetSpeedAxis: { min: number; max: number },
	targetYAxis: { min: number; max: number },
	grid: number[][],
	speedAxis: { min: number; max: number },
	yAxis: { min: number; max: number }
): number[][] => {
	const originalSize = { x: grid[0]?.length ?? 0, y: grid.length }

	const fittedGrid: number[][] = Array.from({ length: targetSize.y }, () =>
		Array.from({ length: targetSize.x }, () => 0)
	)

	for (let x_ = 0; x_ < targetSize.x; x_++) {
		for (let y_ = 0; y_ < targetSize.y; y_++) {
			// Swapping x and y to fit in heatmap
			const normalizedX =
				(y_ / (targetSize.y - 1)) * (targetYAxis.max - targetYAxis.min) + targetYAxis.min
			const normalizedY =
				(x_ / (targetSize.x - 1)) * (targetSpeedAxis.max - targetSpeedAxis.min) +
				targetSpeedAxis.min

			const originalX = Math.floor(
				((normalizedX - yAxis.min) / (yAxis.max - yAxis.min)) * (originalSize.x - 1)
			)
			const originalY = Math.floor(
				((normalizedY - speedAxis.min) / (speedAxis.max - speedAxis.min)) *
					(originalSize.y - 1)
			)

			if (fittedGrid[y_] !== undefined) {
				if (fittedGrid[y_]![x_] !== undefined) {
					fittedGrid[y_]![x_] = grid[originalY]?.[originalX] ?? 0
				}
			}
		}
	}

	let resizedGrid: number[][]
	if (originalSize.x !== targetSize.x || originalSize.y !== targetSize.y) {
		if (originalSize.x > targetSize.x || originalSize.y > targetSize.y) {
			resizedGrid = downsampleGridGPT(fittedGrid, targetSize)
		} else {
			resizedGrid = upsampleGridGPT(fittedGrid, targetSize)
		}
	} else {
		resizedGrid = fittedGrid
	}

	return resizedGrid
}
export const downsampleGridGPT = (
	grid: number[][],
	targetSize: { x: number; y: number }
): number[][] => {
	const originalSize = { x: grid[0]?.length ?? 0, y: grid.length }
	const ratio = { x: originalSize.x / targetSize.x, y: originalSize.y / targetSize.y }

	const downsampledGrid: number[][] = []

	for (let y = 0; y < targetSize.y; y++) {
		const sums: number[] = []
		for (let x = 0; x < targetSize.x; x++) {
			let sum = 0
			let count = 0
			for (let yy = 0; yy < ratio.y; yy++) {
				for (let xx = 0; xx < ratio.x; xx++) {
					const originalY = Math.floor(y * ratio.y + yy)
					const originalX = Math.floor(x * ratio.x + xx)
					sum += grid[originalY]?.[originalX] ?? 0
					count++
				}
			}
			sums.push(sum / count)
		}
		downsampledGrid.push(sums)
	}

	return downsampledGrid
}

export const upsampleGridGPT = (
	grid: number[][],
	targetSize: { x: number; y: number }
): number[][] => {
	const originalSize = { x: grid[0]?.length ?? 0, y: grid.length }
	const ratio = { x: targetSize.x / originalSize.x, y: targetSize.y / originalSize.y }

	const upsampledGrid: number[][] = []

	for (let y = 0; y < targetSize.y; y++) {
		const row: number[] = []
		for (let x = 0; x < targetSize.x; x++) {
			const originalY = Math.floor(y / ratio.y)
			const originalX = Math.floor(x / ratio.x)

			// Linear interpolation
			const yRatio = y / ratio.y - originalY
			const xRatio = x / ratio.x - originalX

			const topLeft = grid[originalY]?.[originalX] ?? 0
			const topRight = grid[originalY]?.[originalX + 1] ?? topLeft
			const bottomLeft = grid[originalY + 1]?.[originalX] ?? topLeft
			const bottomRight = grid[originalY + 1]?.[originalX + 1] ?? topLeft

			const top = topLeft * (1 - xRatio) + topRight * xRatio
			const bottom = bottomLeft * (1 - xRatio) + bottomRight * xRatio

			const value = top * (1 - yRatio) + bottom * yRatio
			row.push(value)
		}
		upsampledGrid.push(row)
	}

	return upsampledGrid
}

// export const fitGrid = (
// 	targetSize: number,
// 	targetSpeedAxis: { min: number; max: number },
// 	targetYAxis: { min: number; max: number },
// 	grid: number[][],
// 	speedAxis: { min: number; max: number },
// 	yAxis: { min: number; max: number }
// ): number[][] => {
// 	let resizedGrid: number[][]
// 	const originalSize = grid.length

// 	const fittedGrid: number[][] = Array.from({ length: originalSize }, () =>
// 		Array.from({ length: originalSize }, () => 0)
// 	)

// 	for (let x_ = 0; x_ < originalSize; x_++) {
// 		for (let y_ = 0; y_ < originalSize; y_++) {
// 			//Swapping x and y to fit in heatmap
// 			const normalizedX =
// 				(y_ / (originalSize - 1)) * (targetYAxis.max - targetYAxis.min) + targetYAxis.min
// 			const normalizedY =
// 				(x_ / (originalSize - 1)) * (targetSpeedAxis.max - targetSpeedAxis.min) +
// 				targetSpeedAxis.min

// 			const originalX = Math.floor(
// 				((normalizedX - yAxis.min) / (yAxis.max - yAxis.min)) * (originalSize - 1)
// 			)
// 			const originalY = Math.floor(
// 				((normalizedY - speedAxis.min) / (speedAxis.max - speedAxis.min)) *
// 					(originalSize - 1)
// 			)

// 			if (fittedGrid[y_] !== undefined) {
// 				if (fittedGrid[y_]![x_] !== undefined) {
// 					fittedGrid[y_]![x_] = grid[originalY]?.[originalX] ?? 0
// 				}
// 			}
// 		}
// 	}

// 	if (originalSize > targetSize) {
// 		resizedGrid = downsampleGrid(fittedGrid, targetSize)
// 	} else if (originalSize < targetSize) {
// 		resizedGrid = upsampleGrid(fittedGrid, targetSize)
// 	} else {
// 		resizedGrid = fittedGrid
// 	}

// 	return resizedGrid
// }

// export const sumMultipleGrids = (grids: number[][][], gridSize: number): number[][] => {
// 	// Initialize a 50x50 grid with zeros
// 	const summedGrid: number[][] = Array.from({ length: gridSize }, () =>
// 		Array.from({ length: gridSize }, () => 0)
// 	)

// 	// Sum all grids using map
// 	grids.forEach((grid) => {
// 		if (grid.length === gridSize) {
// 			grid.forEach((row, y) => {
// 				row.forEach((value, x) => {
// 					summedGrid[y]![x]! += value
// 				})
// 			})
// 		} else {
// 			console.log("Wrong grid size on input grid:", grid.length, "expected", gridSize)
// 		}
// 	})

// 	return summedGrid
// }

export const sumMultipleGridsGPT = (
	grids: number[][][],
	gridSize: { x: number; y: number }
): number[][] => {
	// Initialize a grid with zeros based on provided row and column sizes
	const summedGrid: number[][] = Array.from({ length: gridSize.y }, () =>
		Array.from({ length: gridSize.x }, () => 0)
	)

	// Sum all grids using map
	grids.forEach((grid) => {
		if (grid.length === gridSize.y && grid[0]?.length === gridSize.x) {
			grid.forEach((row, y) => {
				row.forEach((value, x) => {
					summedGrid[y]![x]! += value
				})
			})
		} else {
			console.log(
				"Wrong grid size on input grid:",
				grid.length,
				"x",
				grid[0]?.length,
				"expected",
				gridSize.x,
				"x",
				gridSize.y
			)
		}
	})

	return summedGrid
}
export const normalizeGrid = (grid: number[][]): HeatmapCell[][] => {
	const flatValues = grid.flat()
	const min = Math.min(...flatValues)
	const max = Math.max(...flatValues)
	const sorted = flatValues.sort((a, b) => b - a)
	const almostMax = sorted[2] ?? max
	const tempGrid = grid.map((row) =>
		row.map((value) => ({
			count: value,
			normalized: (value - min) / (almostMax - min), //value > 0 ? 1 : 0, //
		}))
	)
	return tempGrid
}

// const getColorForValue = (value: number): string => {
// 	const colors = [
// 		"#ffffff",
// 		"#bbffff",
// 		"#bbffff",
// 		"#00ff80",
// 		"#80ff00",
// 		"#ffff00",
// 		"#ff8000",
// 		"#ff0000",
// 	]

// 	const index = Math.min(Math.floor(value * (colors.length - 1)), colors.length - 2)
// 	const fraction = value * (colors.length - 1) - index

// 	// Simple linear interpolation between the two colors
// 	const color1 = colors[index] ?? "#ff0000"
// 	const color2 = colors[index + 1] ?? "#ff0000"
// 	const hex = (color: string) => parseInt(color.slice(1), 16)
// 	const lerp = (start: number, end: number, fraction: number): number =>
// 		Math.round(start + (end - start) * fraction)

// 	// Decompose the hex colors to RGB
// 	const r1 = (hex(color1) >> 16) & 255
// 	const g1 = (hex(color1) >> 8) & 255
// 	const b1 = hex(color1) & 255
// 	const r2 = (hex(color2) >> 16) & 255
// 	const g2 = (hex(color2) >> 8) & 255
// 	const b2 = hex(color2) & 255

// 	// Interpolate, recompose to hex, and return
// 	const r = lerp(r1, r2, fraction)
// 	const g = lerp(g1, g2, fraction)
// 	const b = lerp(b1, b2, fraction)
// 	return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, "0")}`
// }

export const bgHeatmapPlugin = {
	id: "heatmapBackground",
	// Using a map to hold state for each chart
	chartStates: new Map<string, ChartState>(),
	beforeInit(chart: ChartJS) {
		// Initialize state for each chart
		this.chartStates.set(chart.id, {
			memory: null,
		})
	},

	beforeDatasetsDraw(
		chart: ChartJS,
		args: { cancelable: true },
		pluginOptions: {
			heatmapData: HeatMapDataType
		}
	) {
		const state = this.chartStates.get(chart.id)
		if (pluginOptions.heatmapData === undefined) {
			return
		}

		const currentDataSnapshot = JSON.stringify(pluginOptions.heatmapData)

		const {
			ctx,
			chartArea: { width, height, left, top },
		} = chart

		if (state !== undefined) {
			if (state.memory?.lastDataSnapshot !== currentDataSnapshot) {
				const heatmapCanvas = new OffscreenCanvas(500, 500)
				const ctxOffscreen = heatmapCanvas.getContext("2d")

				if (ctxOffscreen) {
					ctxOffscreen.clearRect(0, 0, heatmapCanvas.width, heatmapCanvas.height)

					for (const [y, ySegment] of pluginOptions.heatmapData.grid.entries()) {
						for (const [x, cell] of ySegment.entries()) {
							// const color = getColorForValue(Math.pow(cell.normalized, 0.2))
							const mixWeight = Math.pow(cell.normalized, 0.4)

							const color1 = `color-mix(in hsl shorter hue , #FF0000 ${
								mixWeight * 100
							}%, #00BB22 ${100 - mixWeight * 100}%)`
							const color2 = `color-mix(in hsl shorter hue, ${color1} ${Math.min(
								mixWeight * 200,
								100
							)}%, #01B0FF70 ${100 - Math.min(mixWeight * 200, 100)}%)`
							const finalColor = `color-mix(in hsl shorter hue, ${color2} ${Math.min(
								mixWeight * 600,
								100
							)}%, transparent)`

							ctxOffscreen.fillStyle = finalColor
							const cellWidth =
								heatmapCanvas.width / pluginOptions.heatmapData.xSegments
							const cellHeight =
								heatmapCanvas.height / pluginOptions.heatmapData.ySegments

							ctxOffscreen.fillRect(
								x * cellWidth,
								y * cellHeight,
								cellWidth,
								cellHeight
							)
						}
					}

					state.memory = {
						background: ctxOffscreen.canvas,
						lastDataSnapshot: currentDataSnapshot,
					}
				}
			}

			if (state.memory !== null) {
				ctx.save()
				ctx.beginPath()
				ctx.rect(left, top, width, height)
				ctx.clip()
				ctx.globalCompositeOperation = "destination-over"
				ctx.globalAlpha = 0.5
				ctx.translate(left, height + top)
				ctx.scale(1, -1)
				//ctx.filter = "blur(3px) contrast(1.5)"
				ctx.drawImage(state.memory.background, 0, 0, width, height)
				ctx.filter = "none"
				ctx.restore()
			}
		}
	},

	destroy(
		chart: ChartJS,
		pluginOptions: {
			heatmapData: HeatMapDataType
		}
	) {
		if (pluginOptions.heatmapData === undefined) {
			return
		}
		this.chartStates.delete(chart.id)
	},
}
