import { useAppSelector } from "@redux/app/hooks"
import { RequestStatus } from "@redux/app/RequestStatus"
import { RootState } from "@redux/app/store"
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { common } from "@utils/common"
import { requestApi2 } from "@utils/http"
import {
	endpoints,
	GetVesselInfoArgs,
	OpModeLiveData,
	VesselInfoOperation,
	VesselTimelineData,
} from "api"
import { getCurrentCompany } from "../currentCompany/currentCompanySlice"

export const fetchTimelineByVessel = createAsyncThunk<
	VesselTimelineData & { name: string },
	GetVesselInfoArgs
>(
	"selectedVessel/fetchTimelineByVessel",
	async (postData: GetVesselInfoArgs): Promise<VesselTimelineData & { name: string }> => {
		const currentCompany = useAppSelector(getCurrentCompany)

		let response: VesselTimelineData[] | null = []
		const postDataVessel0 = postData.vessels[0]
		if (postDataVessel0 === undefined) {
			return {
				name: "",
				track: undefined,
				visitedLocations: undefined,
				operationModeEvents: undefined,
				plantId: -1,
			}
		}
		let vesselName = "unknown vessel"
		console.log("🚀 ~ vesselName:", vesselName)
		if (postDataVessel0.plantId === 0) {
			postDataVessel0.plantId = 1004
			response = await requestApi2(endpoints.getVesselTimelineData, postData)
			vesselName =
				common.plantDataFromOwnerAndId.get(
					`${(currentCompany ?? "unknown company").toLowerCase()}/${1004}`
				)?.display_name ?? "unknown vessel"
		} else {
			response = await requestApi2(endpoints.getVesselTimelineData, postData)
			const plantId = postData.vessels?.[0]?.plantId
			vesselName =
				common.plantDataFromOwnerAndId.get(
					`${(currentCompany ?? "unknown company").toLowerCase()}/${plantId}`
				)?.display_name ?? "unknown vessel"
		}
		const response_0 = response !== null ? response[0] : undefined

		if (response_0 === undefined) {
			return {
				name: "",
				track: undefined,
				visitedLocations: undefined,
				operationModeEvents: undefined,
				plantId: -1,
			}
		}
		return { ...response_0, name: vesselName }
	}
)
export type VesselConnectionStatus = "online" | "offline"

export type VesselInfo = VesselInfoOperation &
	VesselTimelineData & { latestOperationMode?: OpModeLiveData } & {
		name: string
	} & { condition?: string | VesselConnectionStatus }

type SelectedVessel = {
	vesselInfo: VesselInfo
	status: RequestStatus
	error: string
}

const initialState: SelectedVessel = {
	vesselInfo: {
		name: "",
		position: undefined,
		latestOperationMode: undefined,
		vessel: undefined,
		track: undefined,
		visitedLocations: undefined,
		operationModeEvents: undefined,
		plantId: -1,
	},
	status: RequestStatus.idle,
	error: "",
}

const selectedVesselSlice = createSlice({
	name: "selectedVessel",
	initialState,
	reducers: {
		setSelectedVessel(
			state,
			action: PayloadAction<VesselInfoOperation & { latestOperationMode?: OpModeLiveData }>
		) {
			return { ...state, vesselInfo: { ...state.vesselInfo, ...action.payload } }
		},
		clearSelectedVessel(state) {
			return { ...state, ...initialState }
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchTimelineByVessel.pending, (state, action) => {
				state.status = RequestStatus.loading
			})
			.addCase(fetchTimelineByVessel.fulfilled, (state, action) => {
				state.status = RequestStatus.succeeded
				state.vesselInfo = {
					...state.vesselInfo,
					operationModeEvents: action.payload.operationModeEvents,
					track: action.payload.track,
					visitedLocations: action.payload.visitedLocations,
				}
			})
			.addCase(fetchTimelineByVessel.rejected, (state, action) => {
				state.status = RequestStatus.failed
				state.error = "" //action.error.message
			})
	},
})

export const getSelectedVessel = (state: RootState) => state.selectedVessel.vesselInfo

export const { setSelectedVessel, clearSelectedVessel } = selectedVesselSlice.actions

export default selectedVesselSlice.reducer
