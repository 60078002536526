import { Chart as ChartJS, ChartType } from "chart.js"

declare module "chart.js" {
	interface PluginOptionsByType<TType extends ChartType> {
		customCanvasBackgroundColor?: { color?: string }
	}
}

export const customCanvasBackgroundColorPlugin = {
	id: "customCanvasBackgroundColor",
	beforeDraw: (chart: ChartJS, args: { cancelable: true }, options: { color: string }) => {
		const {
			ctx,
			chartArea: { top, height, width, left },
		} = chart
		ctx.save()
		ctx.globalCompositeOperation = "destination-over"
		ctx.fillStyle = options.color || "#eeeeee55"
		ctx.fillRect(left, top, width, height)
		ctx.restore()
	},
}
