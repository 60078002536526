import { VariantProps, cva } from "class-variance-authority"
import { DetailedHTMLProps, HTMLAttributes, forwardRef } from "react"
import { cn } from "../../lib/utils"

const reportPageBodyVariants = cva(
	"grid h-[calc(2602px-200px-40px-100px)] grid-flow-row w-full grid-rows-[repeat(60,minmax(0,1fr))] grid-cols-[repeat(60,minmax(0,1fr))] content-start justify-start gap-4",
	{
		variants: {
			company: {
				dipai: "",
			},
		},
		defaultVariants: {
			company: "dipai",
		},
	}
)

export interface IReportPageBodyProps
	extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
		VariantProps<typeof reportPageBodyVariants> {}

const ReportPageBody = forwardRef<HTMLDivElement, IReportPageBodyProps>((props, ref) => {
	const { className, children, company, ...rest } = props
	return (
		<div ref={ref} className={cn(reportPageBodyVariants({ company, className }))} {...rest}>
			{children}
		</div>
	)
})

export { ReportPageBody, reportPageBodyVariants }
