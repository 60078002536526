import { RootState } from "@redux/app/store"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export type MapFiltersFacilities = {
	facilities: {
		showPorts: boolean
		showFishFarms: boolean
		showPlatforms: boolean
		showDischargeTerminals: boolean
		showDischargeSTS: boolean
		showAnchorage: boolean
	}
}

export const defaultState: MapFiltersFacilities = {
	facilities: {
		showPorts: true,
		showFishFarms: true,
		showPlatforms: true,
		showDischargeTerminals: true,
		showDischargeSTS: true,
		showAnchorage: true,
	},
}

const storedState = localStorage.getItem("mapFiltersFacilities")
export const initialState: MapFiltersFacilities =
	storedState !== null ? JSON.parse(storedState) : defaultState

const mapFiltersFacilitiesSlice = createSlice({
	name: "mapFiltersFacilities",
	initialState,
	reducers: {
		updateMapFiltersFacilities(state, action: PayloadAction<MapFiltersFacilities>) {
			localStorage.setItem("mapFiltersFacilities", JSON.stringify(action.payload))
			return { ...state, ...action.payload }
		},
		resetMapFiltersFacilities(state) {
			localStorage.setItem("mapFiltersFacilities", JSON.stringify(defaultState))
			return { ...state, ...defaultState }
		},
	},
})

export const { updateMapFiltersFacilities, resetMapFiltersFacilities } =
	mapFiltersFacilitiesSlice.actions

export const getMapFiltersFacilities = (state: RootState) => state.mapFiltersFacilities
export default mapFiltersFacilitiesSlice.reducer
