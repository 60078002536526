import { RequestStatus } from "@redux/app/RequestStatus"
import { RootState } from "@redux/app/store"
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { PlantChannel } from "api"

type State = {
	controlPanelFeedList: PlantChannel[]
	selectedControlPanelFeedList: PlantChannel[]
	status: RequestStatus
	error: string
}

const initialState: State = {
	controlPanelFeedList: [],
	selectedControlPanelFeedList: [],
	status: RequestStatus.idle,
	error: "",
}

export const fetchControlPanelFeedList = createAsyncThunk<PlantChannel[]>(
	"controlPanelFeedList/fetchControlPanelFeedList",
	async (): Promise<PlantChannel[]> => {
		const postData = {}
		// let res = await fetch("/api/getControlPanelFeedList", {
		// 	method: "POST",
		// 	mode: "cors",
		// 	cache: "no-cache",
		// 	credentials: "same-origin",
		// 	headers: {
		// 		"Content-Type": "application/json",
		// 	},
		// 	body: JSON.stringify(postData),
		// })
		// let data: any = JSON.parse(await res.json())

		// return data
		return []
	}
)

const controlPanelFeedListSlice = createSlice({
	name: "controlPanelFeedList",
	initialState,
	reducers: {
		setControlPanelFeedList(state, action: PayloadAction<PlantChannel[]>) {
			return { ...state, controlPanelFeedList: action.payload }
		},
		updateSelectedControlPanelFeedList(state, action: PayloadAction<PlantChannel[]>) {
			return { ...state, selectedControlPanelFeedList: action.payload }
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchControlPanelFeedList.pending, (state, action) => {
				state.status = RequestStatus.loading
			})
			.addCase(fetchControlPanelFeedList.fulfilled, (state, action) => {
				state.status = RequestStatus.succeeded
				state.controlPanelFeedList = action.payload
			})
			.addCase(fetchControlPanelFeedList.rejected, (state, action) => {
				state.status = RequestStatus.failed
				state.error = "" //action.error.message
			})
	},
})

export const getControlPanelFeedList = (state: RootState) =>
	state.controlPanelFeedList.controlPanelFeedList

export const getSelectedControlPanelFeedList = (state: RootState) =>
	state.controlPanelFeedList.selectedControlPanelFeedList

export const getControlPanelFeedListStatus = (state: RootState) => state.controlPanelFeedList.status

export const { setControlPanelFeedList, updateSelectedControlPanelFeedList } =
	controlPanelFeedListSlice.actions

export default controlPanelFeedListSlice.reducer
