import * as Tooltip from "@radix-ui/react-tooltip"
import { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { useSearchParamsState } from "../../../lib/hooks/useSearchParamsState"
import { usePageLayoutContext } from "../PageLayout"

const NavItem = ({
	isExpanded,
	selectedModule,
	name,
	translationFunc,
	icon,
}: {
	isExpanded: boolean
	selectedModule: string
	name: string
	translationFunc: ReturnType<typeof useTranslation>["t"] | undefined
	icon?: ReactNode
}) => {
	return (
		<Tooltip.Provider>
			<Tooltip.Root delayDuration={750}>
				<Tooltip.Trigger
					className={`${
						selectedModule === name
							? `rounded-none bg-black bg-opacity-20 shadow-[inset_0px_-1.5px_1px_hsla(0,0%,100%,0.2),inset_2px_3px_2px_hsla(0,0%,0%,0.2)]`
							: `hover:bg-white hover:bg-opacity-10 hover:shadow-[inset_0px_-2px_2px_hsla(0,0%,0%,0.2),inset_0px_1.5px_1px_hsla(0,0%,100%,0.1),0px_4px_4px_hsla(0,0%,0%,0.2),0px_1px_2px_hsla(0,0%,0%,0.2)]`
					} ${
						isExpanded === false ? `w-[60px]` : `w-[180px]`
					} focus-style transition-all duration-300`}
				>
					<div tabIndex={-1} className={`group flex flex-row`}>
						<div
							tabIndex={-1}
							className={`flex h-[52px] items-center pl-[14px] ${
								selectedModule === name ? `cursor-default` : ``
							}`}
						>
							{icon === undefined ? (
								<div
									className={`h-[26px] w-[26px] pl-3 text-title5 leading-[26px] ${
										selectedModule === name
											? `text-dipai-primary-501`
											: `text-white text-opacity-60 group-hover:text-opacity-100`
									} `}
								>
									{(translationFunc !== undefined
										? translationFunc(name.toUpperCase())
										: name
									)
										.split(" ")
										.map((word) => word[0]?.toUpperCase())}
								</div>
							) : (
								<div
									className={`pr-3 duration-300 ${
										selectedModule === name
											? `text-dipai-primary-501`
											: `text-white opacity-60 group-hover:opacity-100`
									} `}
								>
									{icon}
								</div>
							)}
						</div>
						<div
							tabIndex={-1}
							className={`h-[52px] w-[130px] origin-left pr-3 ${
								isExpanded === false ? `scale-x-0` : `scale-x-100`
							} ${selectedModule === name ? `cursor-default` : ``}`}
						>
							<div className={`flex h-full items-center overflow-hidden`}>
								<p
									className={`break-words text-left text-button font-semibold leading-[16px] duration-300 ${
										selectedModule !== name
											? `text-white group-hover:text-opacity-100`
											: `text-dipai-primary-501`
									} ${
										isExpanded === false
											? `w-full text-opacity-0`
											: `w-full ${
													selectedModule !== name
														? `text-opacity-60`
														: `text-opacity-100`
											  }`
									}`}
								>
									{translationFunc !== undefined
										? translationFunc(name.toUpperCase())
										: name}
								</p>
							</div>
						</div>
					</div>
				</Tooltip.Trigger>
				<Tooltip.Portal>
					<Tooltip.Content
						className={`${
							isExpanded === true ? `hidden` : `block`
						} z-[10001] h-auto w-auto rounded-sm bg-dipai-secondary-800 bg-opacity-[0.85] px-2 py-1 text-white text-opacity-100`}
						sideOffset={5}
						side={`right`}
					>
						{translationFunc !== undefined ? translationFunc(name.toUpperCase()) : name}
						<Tooltip.Arrow className={`fill-dipai-secondary-800 opacity-[0.85]`} />
					</Tooltip.Content>
				</Tooltip.Portal>
			</Tooltip.Root>
		</Tooltip.Provider>
	)
}
interface IMainNavItemProps
	extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	name: string
	icon?: ReactNode
}

export const MainNavItem: FC<IMainNavItemProps> = (props: IMainNavItemProps) => {
	const { name, icon } = props
	const [paramSelectedVessel, setParamSelectedVessel] = useSearchParamsState("vessel", "")
	const {
		selectedModule,
		setSelectedModule,
		isMainNavExpanded,
		isMainNavPopupExpanded,
		translationFunc,
	} = usePageLayoutContext()

	return (
		<Link
			to={
				selectedModule === name
					? `#`
					: `../${name}${
							paramSelectedVessel !== ""
								? `?vessel=${paramSelectedVessel.replaceAll(" ", "+")}`
								: ``
					  }`
			}
			tabIndex={-1}
		>
			<div className={`hidden lg:flex`}>
				<NavItem
					isExpanded={isMainNavExpanded}
					selectedModule={selectedModule}
					name={name}
					translationFunc={translationFunc}
					icon={icon}
				/>
			</div>
			<div className={`flex sm:flex lg:hidden`}>
				<NavItem
					isExpanded={isMainNavPopupExpanded}
					selectedModule={selectedModule}
					name={name}
					translationFunc={translationFunc}
					icon={icon}
				/>
			</div>
		</Link>
	)
}
